
































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import User from '@/models/User';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import JobsFilterData from '@/misc/JobOccurrencesFilterData';
import {userStoreActions, userStoreGetter} from '@/stores/user.store';
import {customerStoreActions, customerStoreGetter, customerStoreMutations} from '@/stores/customer.store';
import {locationStoreActions, locationStoreGetter} from '@/stores/location.store';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');
const LocationStore = namespace('location');

@Component({
  components: {
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
  },
})
export default class JobFilterComponent extends Vue {

  @Prop({default: () => new JobsFilterData()})
  public filterData!: JobsFilterData;

  public selectedCustomer: Customer | null = null;
  public selectedUsers: User[] = [];
  public selectedManager: User[] = [];
  public selectedLocations: Location[] = [];
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string }) => Promise<User[]>;
  @CustomerStore.Mutation(customerStoreMutations.STORE_CUSTOMER)
  private storeCustomer!: (customer?: Customer) => void;
  @LocationStore.Getter(locationStoreGetter.LOCATIONS)
  private _locations!: Location[];

  public get manager(): User[] {
    return this._users;
  }

  get locations(): Location[] {
    return this._locations;
  }

  @UserStore.Getter(userStoreGetter.USERS)
  private _users!: User[];

  public get users(): User[] {
    return this._users;
  }

  @CustomerStore.Getter(customerStoreGetter.CUSTOMERS)
  private _customers!: Customer[];

  public get customers(): Customer[] {
    return this._customers ? this._customers : [];
  }

  public _groupBy: 'none' | 'customer' | 'employee' = 'none';

  // Getter and Setter for GroupBy instead of watch
  private get groupBy() {
    return this._groupBy;
  }

  private set groupBy(value: 'none' | 'customer' | 'employee') {
    this.$emit('change-group', value);
    this._groupBy = value;
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  public async created() {
    try {
      // load all data that is required for this view
      await this.loadUsersAction({tenantId: this.$route.params.tenantId});
      // rest customer data that is possible available
      this.storeCustomer(undefined);

      // process url filter values
      this.selectedUsers = this.users.filter((user: User) =>
          this.filterData.users!.indexOf(user.id!) !== -1);

    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * Notifies parent component about filter change
   */
  public onMainFilterChange() {
    const filterData: Partial<JobsFilterData> = {
      driverId: this.selectedUsers.map((item) => item.id) as string[],
      managerId: this.selectedManager.map((item) => item.id) as string[],
      locationId: this.selectedLocations.map((item) => item.id) as string[],
    };
    this.$emit('main-filter-change', filterData);
  }

  /**
   * Support function to get user name
   * @param user
   */
  public getUserFullName(user: User): string {
    return user.fullName;
  }

  public getLocationName(location: Location): string {
    return location.name!;
  }

  private resetFilter() {
    this.selectedCustomer = null;
    this.selectedLocations = [];
    this.selectedManager = [];
    this.selectedUsers = [];
    this.onMainFilterChange();
  }
}
