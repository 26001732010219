var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column justify-center align-self-start custom-border filter-background px-4 py-5"},[_c('div',{staticClass:"mb-3 d-flex filter-header subheader--text font-weight-medium"},[_c('span',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t('JOB_OCCURRENCE_OVERVIEW.MAIN_FILTER_TITLE'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"ml-auto"},[_c('v-btn',_vm._g({attrs:{"icon":"","small":"","text":""},on:{"click":function($event){return _vm.resetFilter()}}},on),[_c('v-icon',{attrs:{"color":"subheader"}},[_vm._v(" mdi-reload ")])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('JOB_OCCURRENCE_OVERVIEW.RESET_FILTER'))+" ")])],1),(_vm.$userRoleHandler.hasPermission('USER_READ_OWN'))?_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('ROLES.OBJECTMANAGER'))+" ")]):_vm._e(),(_vm.$userRoleHandler.hasPermission('USER_READ_OWN'))?_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"item-text":_vm.getUserFullName,"item-value":_vm.getUserId,"items":_vm.manager,"multiple":true,"return-object":true},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.fullName)+" "),_c('p',{staticClass:"email-selection"},[_vm._v(_vm._s(item.email))])])]}}],null,false,716665223),model:{value:(_vm.selectedManager),callback:function ($$v) {_vm.selectedManager=$$v},expression:"selectedManager"}}):_vm._e(),(_vm.$userRoleHandler.hasPermission('USER_READ_OWN'))?_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.EMPLOYEE'))+" ")]):_vm._e(),(_vm.$userRoleHandler.hasPermission('USER_READ_OWN'))?_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"item-text":_vm.getUserFullName,"item-value":_vm.getUserId,"items":_vm.users,"multiple":true,"return-object":true},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.fullName)+" "),_c('p',{staticClass:"email-selection"},[_vm._v(_vm._s(item.email))])])]}}],null,false,716665223),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}):_vm._e(),(_vm.$userRoleHandler.hasPermission('LOCATION_READ_OWN'))?_c('span',{staticClass:"mb-2 subheader--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LOCATION'))+" ")]):_vm._e(),(_vm.$userRoleHandler.hasPermission('LOCATION_READ_OWN'))?_c('RJAutocomplete',{staticClass:"mb-4",attrs:{"clearable":true,"disabled":_vm.locations.length <= 0,"items":_vm.locations,"multiple":true,"return-object":true,"item-text":"name"},on:{"change":function($event){return _vm.onMainFilterChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name))])]}}],null,false,2964920705),model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }